import { nanoid } from 'nanoid';
import { ReactElement } from 'react';
import { Column } from 'react-table';

import { IconManager } from '@speeki/icons';

import { TableBlueText } from '@pages/StaticPlaceholder.styled';

import { IconsWrapper } from './DocumentsPlaceholder.styled';

export interface DocumentsPlaceholderTableRow {
    fileName: string;
    fileSize: string;
    fileType: string;
    icons: {
        download: ReactElement;
        share: ReactElement;
    };
    id: string;
    uploadedBy: string;
    uploadedDate: string;
    usagePlace: string;
}

export const documentsPlaceholderHeaderData: Column<DocumentsPlaceholderTableRow>[] =
    [
        {
            Cell: ({ value }) => <TableBlueText>{value}</TableBlueText>,
            Header: 'File name',
            accessor: 'fileName',
            width: 220,
        },
        {
            Header: 'Where this was used',
            accessor: 'usagePlace',
            width: 250,
        },
        {
            Header: 'Uploaded date',
            accessor: 'uploadedDate',
        },
        {
            Header: 'Uploaded by',
            accessor: 'uploadedBy',
        },
        {
            Header: 'File type',
            accessor: 'fileType',
        },
        {
            Header: 'File size',
            accessor: 'fileSize',
        },
        {
            Cell: ({ value }) => {
                return (
                    <IconsWrapper>
                        {value.share}
                        {value.download}
                    </IconsWrapper>
                );
            },
            Header: '',
            accessor: 'icons',
            disableSortBy: true,
            width: 1,
        },
    ];

const icons: DocumentsPlaceholderTableRow['icons'] = {
    download: (
        <IconManager
            icon="SvgUpload24"
            size={24}
            themeColors={{
                default: {
                    'color-1': 'tertiary.default',
                },
                hover: {
                    'color-1': 'tertiary.hover',
                },
            }}
        />
    ),
    share: (
        <IconManager
            icon="SvgShare24"
            size={24}
            themeColors={{
                default: {
                    'color-1': 'tertiary.default',
                },
                hover: {
                    'color-1': 'tertiary.hover',
                },
            }}
        />
    ),
};

export const documentsPlaceholderTableData: DocumentsPlaceholderTableRow[] = [
    {
        id: nanoid(),
        fileName: 'Process and Procedure v2.3',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Tommy Frenzy',
        uploadedDate: '23-03-2022 15:32',
        usagePlace: 'ESG Profile - Governance Anti Bribery',
    },
    {
        id: nanoid(),
        fileName: 'Process and Procedure v2.3',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Tommy Frenzy',
        uploadedDate: '23-03-2022 15:32',
        usagePlace: 'ESG Profile - Governance Anti Bribery',
    },
    {
        id: nanoid(),
        fileName: 'Meeting minutes 220223',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '15-07-2023 11:56',
        usagePlace: 'ESG Profile - Health and Safety',
    },
    {
        id: nanoid(),
        fileName: 'Suitability Survey',
        fileSize: '24Kb',
        fileType: 'ELSX',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '13-02-2023 12:33',
        usagePlace: 'ESRS',
    },
    {
        id: nanoid(),
        fileName: 'Incident Report',
        fileSize: '42Kb',
        fileType: 'DOCX',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '21-12-2022 04:34',
        usagePlace: 'GRI',
    },
    {
        id: nanoid(),
        fileName: 'Investigation-Report-Team-A',
        fileSize: '233kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Tommy Frenzy',
        uploadedDate: '08-09-2022 10:23',
        usagePlace: 'ESG Profile - Whistleblowing',
    },
    {
        id: nanoid(),
        fileName: 'Risk Assessment Report',
        fileSize: '68kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'Sarah Thompson',
        uploadedDate: '05-06-2023 09:45',
        usagePlace: 'Incident Management',
    },
    {
        id: nanoid(),
        fileName: 'Quarterly Sustainability Report',
        fileSize: '1.2mb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Emma Rodriguez',
        uploadedDate: '17-07-2023 16:21',
        usagePlace: 'ESG Profiling - Environmental',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Supplier Code of Conduct',
        fileSize: '128kb',
        fileType: 'DOC',
        icons,
        uploadedBy: 'Michelle Lewis',
        uploadedDate: '12-11-2022 08:59',
        usagePlace: 'Engaging Stage - Context',
    },
    {
        id: nanoid(),
        fileName: 'Risk Assessment Report',
        fileSize: '68kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'Sarah Thompson',
        uploadedDate: '05-06-2023 09:45',
        usagePlace: 'Incident Management',
    },
    {
        id: nanoid(),
        fileName: 'Quarterly Sustainability Report',
        fileSize: '1.2mb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Emma Rodriguez',
        uploadedDate: '17-07-2023 16:21',
        usagePlace: 'ESG Profiling - Environmental',
    },
    {
        id: nanoid(),
        fileName: 'Incident Report',
        fileSize: '42Kb',
        fileType: 'DOCX',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '21-12-2022 04:34',
        usagePlace: 'GRI',
    },
    {
        id: nanoid(),
        fileName: 'Process and Procedure v2.3',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Tommy Frenzy',
        uploadedDate: '23-03-2022 15:32',
        usagePlace: 'ESG Profile - Governance Anti Bribery',
    },
    {
        id: nanoid(),
        fileName: 'Meeting minutes 220223',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '15-07-2023 11:56',
        usagePlace: 'ESG Profile - Health and Safety',
    },
    {
        id: nanoid(),
        fileName: 'Risk Assessment Report',
        fileSize: '68kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'Sarah Thompson',
        uploadedDate: '05-06-2023 09:45',
        usagePlace: 'Incident Management',
    },
    {
        id: nanoid(),
        fileName: 'Quarterly Sustainability Report',
        fileSize: '1.2mb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Emma Rodriguez',
        uploadedDate: '17-07-2023 16:21',
        usagePlace: 'ESG Profiling - Environmental',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Supplier Code of Conduct',
        fileSize: '128kb',
        fileType: 'DOC',
        icons,
        uploadedBy: 'Michelle Lewis',
        uploadedDate: '12-11-2022 08:59',
        usagePlace: 'Engaging Stage - Context',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Quarterly Sustainability Report',
        fileSize: '1.2mb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Emma Rodriguez',
        uploadedDate: '17-07-2023 16:21',
        usagePlace: 'ESG Profiling - Environmental',
    },
    {
        id: nanoid(),
        fileName: 'Incident Report',
        fileSize: '42Kb',
        fileType: 'DOCX',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '21-12-2022 04:34',
        usagePlace: 'GRI',
    },
    {
        id: nanoid(),
        fileName: 'Process and Procedure v2.3',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Tommy Frenzy',
        uploadedDate: '23-03-2022 15:32',
        usagePlace: 'ESG Profile - Governance Anti Bribery',
    },
    {
        id: nanoid(),
        fileName: 'Meeting minutes 220223',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '15-07-2023 11:56',
        usagePlace: 'ESG Profile - Health and Safety',
    },
    {
        id: nanoid(),
        fileName: 'Risk Assessment Report',
        fileSize: '68kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'Sarah Thompson',
        uploadedDate: '05-06-2023 09:45',
        usagePlace: 'Incident Management',
    },
    {
        id: nanoid(),
        fileName: 'Risk Assessment Report',
        fileSize: '68kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'Sarah Thompson',
        uploadedDate: '05-06-2023 09:45',
        usagePlace: 'Incident Management',
    },
    {
        id: nanoid(),
        fileName: 'Supplier Code of Conduct',
        fileSize: '128kb',
        fileType: 'DOC',
        icons,
        uploadedBy: 'Michelle Lewis',
        uploadedDate: '12-11-2022 08:59',
        usagePlace: 'Engaging Stage - Context',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Quarterly Sustainability Report',
        fileSize: '1.2mb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Emma Rodriguez',
        uploadedDate: '17-07-2023 16:21',
        usagePlace: 'ESG Profiling - Environmental',
    },
    {
        id: nanoid(),
        fileName: 'Incident Report',
        fileSize: '42Kb',
        fileType: 'DOCX',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '21-12-2022 04:34',
        usagePlace: 'GRI',
    },
    {
        id: nanoid(),
        fileName: 'Risk Assessment Report',
        fileSize: '68kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'Sarah Thompson',
        uploadedDate: '05-06-2023 09:45',
        usagePlace: 'Incident Management',
    },
    {
        id: nanoid(),
        fileName: 'Quarterly Sustainability Report',
        fileSize: '1.2mb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Emma Rodriguez',
        uploadedDate: '17-07-2023 16:21',
        usagePlace: 'ESG Profiling - Environmental',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Supplier Code of Conduct',
        fileSize: '128kb',
        fileType: 'DOC',
        icons,
        uploadedBy: 'Michelle Lewis',
        uploadedDate: '12-11-2022 08:59',
        usagePlace: 'Engaging Stage - Context',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Quarterly Sustainability Report',
        fileSize: '1.2mb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Emma Rodriguez',
        uploadedDate: '17-07-2023 16:21',
        usagePlace: 'ESG Profiling - Environmental',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Supplier Code of Conduct',
        fileSize: '128kb',
        fileType: 'DOC',
        icons,
        uploadedBy: 'Michelle Lewis',
        uploadedDate: '12-11-2022 08:59',
        usagePlace: 'Engaging Stage - Context',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Incident Report',
        fileSize: '42Kb',
        fileType: 'DOCX',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '21-12-2022 04:34',
        usagePlace: 'GRI',
    },
    {
        id: nanoid(),
        fileName: 'Process and Procedure v2.3',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Tommy Frenzy',
        uploadedDate: '23-03-2022 15:32',
        usagePlace: 'ESG Profile - Governance Anti Bribery',
    },
    {
        id: nanoid(),
        fileName: 'Meeting minutes 220223',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '15-07-2023 11:56',
        usagePlace: 'ESG Profile - Health and Safety',
    },
    {
        id: nanoid(),
        fileName: 'Risk Assessment Report',
        fileSize: '68kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'Sarah Thompson',
        uploadedDate: '05-06-2023 09:45',
        usagePlace: 'Incident Management',
    },
    {
        id: nanoid(),
        fileName: 'Risk Assessment Report',
        fileSize: '68kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'Sarah Thompson',
        uploadedDate: '05-06-2023 09:45',
        usagePlace: 'Incident Management',
    },
    {
        id: nanoid(),
        fileName: 'Supplier Code of Conduct',
        fileSize: '128kb',
        fileType: 'DOC',
        icons,
        uploadedBy: 'Michelle Lewis',
        uploadedDate: '12-11-2022 08:59',
        usagePlace: 'Engaging Stage - Context',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Quarterly Sustainability Report',
        fileSize: '1.2mb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Emma Rodriguez',
        uploadedDate: '17-07-2023 16:21',
        usagePlace: 'ESG Profiling - Environmental',
    },
    {
        id: nanoid(),
        fileName: 'Incident Report',
        fileSize: '42Kb',
        fileType: 'DOCX',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '21-12-2022 04:34',
        usagePlace: 'GRI',
    },
    {
        id: nanoid(),
        fileName: 'Risk Assessment Report',
        fileSize: '68kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'Sarah Thompson',
        uploadedDate: '05-06-2023 09:45',
        usagePlace: 'Incident Management',
    },
    {
        id: nanoid(),
        fileName: 'Quarterly Sustainability Report',
        fileSize: '1.2mb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Emma Rodriguez',
        uploadedDate: '17-07-2023 16:21',
        usagePlace: 'ESG Profiling - Environmental',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Supplier Code of Conduct',
        fileSize: '128kb',
        fileType: 'DOC',
        icons,
        uploadedBy: 'Michelle Lewis',
        uploadedDate: '12-11-2022 08:59',
        usagePlace: 'Engaging Stage - Context',
    },
    {
        id: nanoid(),
        fileName: 'Financial Statements',
        fileSize: '520kb',
        fileType: 'XLSX',
        icons,
        uploadedBy: 'John Collins',
        uploadedDate: '29-01-2023 11:14',
        usagePlace: 'ISSB',
    },
    {
        id: nanoid(),
        fileName: 'Incident Report',
        fileSize: '42Kb',
        fileType: 'DOCX',
        icons,
        uploadedBy: 'Allen Hall',
        uploadedDate: '21-12-2022 04:34',
        usagePlace: 'GRI',
    },
    {
        id: nanoid(),
        fileName: 'Process and Procedure v2.3',
        fileSize: '915kb',
        fileType: 'PDF',
        icons,
        uploadedBy: 'Tommy Frenzy',
        uploadedDate: '23-03-2022 15:32',
        usagePlace: 'ESG Profile - Governance Anti Bribery',
    },
];
